import {
  EuiBasicTable,
  EuiBasicTableProps,
  EuiSkeletonText,
} from "@inscopix/ideas-eui";
import { CallOutError } from "components/CallOutError/CallOutError";
import { ToolSpec } from "components/ToolParamsGrid/ToolParamsGrid.types";
import { Tooltip } from "components/Tooltip/Tooltip";
import { Task, useGetTaskResourceUsageByTaskIdQuery } from "graphql/_Types";
import moment from "moment";
import { ResourceTier, RESOURCE_TIERS_BY_ID } from "types/ResourceTiers";
import { isNonNullish } from "utils/isNonNullish";
import { isNullish } from "utils/isNullish";
import { roundToSignificant } from "utils/roundToSignificant";

type Resource = "CPU" | "GPU" | "Memory";
type Field = "min" | "max";
type TableItem = ({ [x in Field]: number | null } & {
  resource: Resource;
}) & {
  allocated?: number;
  percentOfAllocated?: string;
};

export interface ModalTaskLogsResourceUsageProps {
  task: Pick<Task, "id" | "status" | "created">;
}

export const ModalTaskLogsResourceUsage = ({
  task,
}: ModalTaskLogsResourceUsageProps) => {
  const { loading, error, data } = useGetTaskResourceUsageByTaskIdQuery({
    variables: {
      id: task.id,
    },
    fetchPolicy: "network-only",
  });

  const taskData = data?.task;
  const resourceUsage = taskData?.resourceUsage;

  if (loading) {
    return <EuiSkeletonText lines={3} />;
  }
  if (error || isNullish(resourceUsage) || isNullish(taskData)) {
    return (
      <CallOutError
        title="Unable to retrieve task resource usage information"
        color="primary"
      >
        Resource usage information is not available for this task.
        {moment(task.created).isBefore("2024-11-21") && (
          <>
            <br />
            <br />
            Resource usage is only available for tasks launched after Nov 20,
            2024.
          </>
        )}
      </CallOutError>
    );
  }
  const resourceTier = taskData.resourceTier as ResourceTier["id"] | null;

  const formatNumberOrNull = (val: number | null | undefined) => {
    return isNonNullish(val) ? roundToSignificant(val) : undefined;
  };

  const render = (val: number | undefined | null, col: TableItem) => {
    const formattedValue = formatNumberOrNull(val);
    if (formattedValue === undefined) {
      return;
    }
    let unit = "";
    switch (col.resource) {
      case "CPU":
      case "GPU":
        unit = "%";
        break;
      case "Memory":
        unit = "GB";
        break;
    }
    return `${formattedValue} ${unit}`;
  };
  const columns: (EuiBasicTableProps<TableItem>["columns"][number] & {
    field: Field | "resource" | "allocated" | "percentOfAllocated";
  })[] = [
    {
      field: "resource",
      name: "Resource",
      width: "90px",
    },
    {
      field: "min",
      name: "Minimum",
      render,
      width: "90px",
    },
    {
      field: "max",
      name: "Maximum",
      render,
      width: "90px",
    },
    {
      field: "allocated",
      name: "Allocated",
      render,
      width: "90px",
    },
    {
      field: "percentOfAllocated",

      name: (
        <Tooltip
          content={
            <span>
              May exceed 100%.
              <br />
              Depending on available resources, a larger instance than requested
              may be used.
              <br />
              No additional cost will be incurred.
            </span>
          }
        >
          <span title="">Percent of Allocated *</span>
        </Tooltip>
      ),
      width: "90px",
    },
  ];

  const allocatedResources = (() => {
    if (resourceTier === null) {
      return (taskData?.toolVersion?.toolSpec as ToolSpec | undefined)
        ?.resources;
    }
    return RESOURCE_TIERS_BY_ID[resourceTier];
  })();

  const items: EuiBasicTableProps<TableItem>["items"] = [
    {
      resource: "CPU",
      min: resourceUsage.cpuMin,
      max: resourceUsage.cpuMax,
      allocated:
        allocatedResources?.cpu !== undefined
          ? // count to %
            allocatedResources.cpu * 100
          : undefined,
      get percentOfAllocated() {
        if (this.max !== null && this.allocated !== undefined) {
          const formatted = formatNumberOrNull(
            (this.max / this.allocated) * 100,
          );
          return formatted !== undefined ? `${formatted} %` : undefined;
        }
        return undefined;
      },
    },
    {
      resource: "Memory",
      // MB to GB
      min: resourceUsage.memoryMin / 1000,
      // MB to GB
      max: resourceUsage.memoryMax / 1000,
      allocated:
        allocatedResources?.memory !== undefined
          ? allocatedResources.memory / 1000
          : undefined,
      get percentOfAllocated() {
        if (this.max !== null && this.allocated !== undefined) {
          const formatted = formatNumberOrNull(
            (this.max / this.allocated) * 100,
          );
          return formatted !== undefined ? `${formatted} %` : undefined;
        }
        return undefined;
      },
    },
  ];

  return <EuiBasicTable columns={columns} items={items} />;
};
