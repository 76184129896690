import { keyBy } from "lodash";

/***********
  BASE TYPE
 ***********/

export type FileType = (typeof FILE_TYPES)[keyof typeof FILE_TYPES];

/************************
  FILE TYPE DEFINITIONS
 ************************/

const UNKNOWN = {
  id: 1,
  key: "unknown",
  name: "Unknown",
  icon: "document",
  series: false,
} as const;

const STATISTICS = {
  id: 10,
  key: "statistics",
  name: "Statistics",
  icon: "stats",
  series: false,
} as const;

const BUNDLE = {
  id: 16,
  key: "bundle",
  name: "Bundle",
  icon: "package",
  series: false,
} as const;

const MINISCOPE_MOVIE = {
  id: 17,
  key: "miniscope_movie",
  name: "Miniscope movie",
  icon: "videoPlayer",
  series: true,
} as const;

const NVISION_MOVIE = {
  id: 18,
  key: "nvision_movie",
  name: "nVision movie",
  icon: "videoPlayer",
  series: true,
} as const;

const MINISCOPE_IMAGE = {
  id: 19,
  key: "miniscope_image",
  name: "Miniscope image",
  icon: "image",
  series: false,
} as const;

const CELL_SET = {
  id: 20,
  key: "cell_set",
  name: "Cell set",
  icon: "heatmap",
  series: true,
} as const;

const VESSEL_SET = {
  id: 21,
  key: "vessel_set",
  name: "Vessel set",
  icon: "cluster",
  series: false,
} as const;

const CALCIUM_IMAGING_QC_REPORT = {
  id: 22,
  key: "calcium_image_qc_report",
  name: "Calcium Imaging QC Data",
  icon: "reportingApp",
  series: false,
} as const;

const BEHAVIOR_ANNOTATIONS = {
  id: 23,
  key: "behavior_annotations",
  name: "Behavior Annotations",
  icon: "tag",
  series: false,
} as const;

const MODULATION_DATA = {
  id: 24,
  key: "modulation_data",
  name: "Modulation Data",
  icon: "visLine",
  series: false,
} as const;

const CORRELATION_DATA = {
  id: 25,
  key: "correlation_data",
  name: "Correlation Data",
  icon: "visAreaStacked",
  series: false,
} as const;

const EXPERIMENT_ANNOTATIONS = {
  id: 26,
  key: "experiment_annotations",
  name: "Experiment Annotations",
  icon: "visText",
  series: false,
} as const;

const COMBINED_PERI_EVENT_TRACES = {
  id: 27,
  key: "combined_peri_event_traces",
  name: "Combined Peri Event Traces",
  icon: "fold",
  series: false,
} as const;

const COMBINED_PERI_EVENT_STATISTICS = {
  id: 28,
  key: "combined_peri_event_statistics",
  name: "Combined Peri Event Statistics",
  icon: "stats",
  series: false,
} as const;

const PERI_EVENT_COMPARISON_DATA = {
  id: 29,
  key: "peri_event_comparison_data",
  name: "Peri Event Comparison Data",
  icon: "visAreaStacked",
  series: false,
} as const;

const COMBINED_POPULATION_ACTIVITY_DATA = {
  id: 30,
  key: "combined_population_activity_data",
  name: "Combined Population Activity Data",
  icon: "cluster",
  series: false,
} as const;

const POPULATION_ACTIVITY_COMPARISON_DATA = {
  id: 31,
  key: "population_activity_comparison_data",
  name: "Population Activity Comparison Data",
  icon: "visAreaStacked",
  series: false,
} as const;

const COMBINED_CORRELATION_DATA = {
  id: 32,
  key: "combined_correlation_data",
  name: "Combined Correlation Data",
  icon: "visAreaStacked",
  series: false,
} as const;

const CORRELATION_COMPARISON_DATA = {
  id: 33,
  key: "correlation_comparison_data",
  name: "Correlation Comparison Data",
  icon: "visBarVerticalStacked",
  series: false,
} as const;

const IMAGE = {
  id: 34,
  key: "image",
  name: "Image (general)",
  icon: "image",
  series: false,
} as const;

const MOVIE = {
  id: 35,
  key: "movie",
  name: "Movie (general)",
  icon: "videoPlayer",
  series: false,
} as const;

const NEURAL_EVENTS = {
  id: 36,
  key: "neural_events",
  name: "Neural Events",
  icon: "timeline",
  series: true,
} as const;

const TIMESTAMP_EVENTS = {
  id: 37,
  key: "timestamp_events",
  name: "Timestamp Events",
  icon: "timeline",
  series: true,
} as const;

const EVENT_ALIGNED_NEURAL_DATA = {
  id: 38,
  key: "event_aligned_neural_data",
  name: "Event-Aligned Neural Data",
  icon: "fold",
  series: false,
} as const;

const GPIO_DATA = {
  id: 39,
  key: "gpio_data",
  name: "GPIO Data",
  icon: "symlink",
  series: false,
} as const;

const IMU_DATA = {
  id: 40,
  key: "imu_data",
  name: "IMU Data",
  icon: "visLine",
  series: false,
} as const;

const CALCIUM_IMAGING_QC_DATA = {
  id: 41,
  key: "calcium_image_qc_data",
  name: "Calcium Imaging QC Data",
  icon: "reportingApp",
  series: false,
} as const;

const DLC_MODEL_PREDICTIONS_DATA = {
  id: 42,
  key: "dlc_model_predictions_data",
  name: "DLC Model Predictions Data",
  icon: "packetbeatApp",
  series: false,
} as const;

const NWB_DATA = {
  id: 43,
  key: "nwb_data",
  name: "NWB Data",
  icon: "node",
  series: false,
} as const;

const MOVIE_ZONES = {
  id: 44,
  key: "movie_zones",
  name: "Movie Zones",
  icon: "visMapRegion",
  series: false,
} as const;

const ZONE_OCCUPANCY_ANALYSIS = {
  id: 45,
  key: "zone_occupancy_analysis",
  name: "Zone Occupancy Analysis",
  icon: "visMapRegion",
  series: false,
} as const;

const LOCOMOTION_COMPARISON_DATA = {
  id: 46,
  key: "locomotion_comparison_data",
  name: "Locomotion Comparison Data",
  icon: "editorItemAlignCenter",
  series: false,
} as const;

const SUITE2P_DATA = {
  id: 47,
  key: "suite2p_data",
  name: "Suite2P Data",
  icon: "package",
  series: false,
} as const;

const CONFIG = {
  id: 48,
  key: "config",
  name: "Config",
  icon: "tableDensityExpanded",
  series: false,
} as const;

const DLC_MODEL = {
  id: 49,
  key: "dlc_model",
  name: "DLC Model",
  icon: "package",
  series: false,
} as const;

const BRUKER2P_MOVIE = {
  id: 50,
  key: "bruker2p_movie",
  name: "Bruker 2P Movie",
  icon: "videoPlayer",
  series: false,
} as const;

const SUITE2P_CLASSIFIER = {
  id: 51,
  key: "suite2p_classifier",
  name: "suite2p Classifier",
  icon: "dataVisualizer",
  series: false,
} as const;

const CAIMAN_DATA = {
  id: 52,
  key: "caiman_data",
  name: "CaImAn Data",
  icon: "heatmap",
  series: false,
} as const;

const DLC_LABELED_DATA = {
  id: 53,
  key: "dlc_labeled_data",
  name: "DLC Labeled Data",
  icon: "packetbeatApp",
  series: false,
} as const;

const DLC_CONFIG = {
  id: 54,
  key: "dlc_config",
  name: "DLC Config",
  icon: "packetbeatApp",
  series: false,
} as const;

const DLC_EVALUATION_RESULTS = {
  id: 55,
  key: "dlc_evaluation_results",
  name: "DLC Evaluation Results",
  icon: "packetbeatApp",
  series: false,
} as const;

const EPOCH_ACTIVITY_DATA = {
  id: 56,
  key: "epoch_activity_data",
  name: "Epoch Activity Data",
  icon: "visLine",
  series: false,
} as const;

const COMBINED_ZONE_OCCUPANCY_ANALYSIS_DATA = {
  id: 57,
  key: "combined_zone_occupancy_analysis_data",
  name: "Combined Zone Occupancy Analysis Data",
  icon: "document",
  series: false,
} as const;

const EPOCH_ACTIVITY_COMPARISON_DATA = {
  id: 58,
  key: "epoch_activity_comparison_data",
  name: "Epoch Activity Comparison Data",
  icon: "visAreaStacked",
  series: false,
} as const;

const MOTION_CORRECTION_QC_DATA = {
  id: 59,
  key: "motion_correction_qc_data",
  name: "Motion Correction QC Data",
  icon: "reportingApp",
  series: false,
} as const;

export const FILE_TYPES = {
  UNKNOWN,
  STATISTICS,
  BUNDLE,
  MINISCOPE_MOVIE,
  NVISION_MOVIE,
  MINISCOPE_IMAGE,
  CELL_SET,
  VESSEL_SET,
  CALCIUM_IMAGING_QC_REPORT,
  BEHAVIOR_ANNOTATIONS,
  MODULATION_DATA,
  CORRELATION_DATA,
  EXPERIMENT_ANNOTATIONS,
  COMBINED_PERI_EVENT_TRACES,
  COMBINED_PERI_EVENT_STATISTICS,
  PERI_EVENT_COMPARISON_DATA,
  COMBINED_POPULATION_ACTIVITY_DATA,
  POPULATION_ACTIVITY_COMPARISON_DATA,
  COMBINED_CORRELATION_DATA,
  CORRELATION_COMPARISON_DATA,
  IMAGE,
  MOVIE,
  NEURAL_EVENTS,
  TIMESTAMP_EVENTS,
  EVENT_ALIGNED_NEURAL_DATA,
  GPIO_DATA,
  IMU_DATA,
  CALCIUM_IMAGING_QC_DATA,
  DLC_MODEL_PREDICTIONS_DATA,
  NWB_DATA,
  MOVIE_ZONES,
  ZONE_OCCUPANCY_ANALYSIS,
  LOCOMOTION_COMPARISON_DATA,
  SUITE2P_DATA,
  CONFIG,
  DLC_MODEL,
  BRUKER2P_MOVIE,
  SUITE2P_CLASSIFIER,
  CAIMAN_DATA,
  DLC_LABELED_DATA,
  DLC_CONFIG,
  DLC_EVALUATION_RESULTS,
  EPOCH_ACTIVITY_DATA,
  COMBINED_ZONE_OCCUPANCY_ANALYSIS_DATA,
  EPOCH_ACTIVITY_COMPARISON_DATA,
  MOTION_CORRECTION_QC_DATA,
} as const;

/*******************
  AGGREGATED EXPORTS
 *******************/

export const FILE_TYPES_BY_ID = keyBy(
  FILE_TYPES,
  (fileType) => fileType.id,
) as {
  [id in FileType["id"]]: FileType;
} & {
  [unknown: number]: FileType | undefined;
};

export const FILE_TYPES_BY_KEY = keyBy(
  FILE_TYPES,
  (fileType) => fileType.key,
) as {
  [key in FileType["key"]]: FileType;
} & {
  [unknown: string]: FileType | undefined;
} & {
  [unknown: number | symbol]: undefined;
};
