/** @jsxImportSource @emotion/react */
import { Global, css } from "@emotion/react";
import {
  EuiButtonEmpty,
  EuiCallOut,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSpacer,
  EuiSuperSelectOption,
  EuiTab,
  EuiTabs,
  EuiText,
} from "@inscopix/ideas-eui";
import { captureException } from "@sentry/react";
import assert from "assert";
import { ButtonPermissioned } from "components/ButtonPermissioned/ButtonPermissioned";
import { ComboBoxPermissioned } from "components/ComboBoxPermissioned/ComboBoxPermissioned";
import { ModalError } from "components/ModalError/ModalError";
import { ModalLoading } from "components/ModalLoading/ModalLoading";
import { UserWithAccess } from "components/ModalProjectSharing/UserWithAccess";
import { SuperSelectPermissioned } from "components/SuperSelectPermissioned/SuperSelectPermissioned";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import {
  ApplicationUser,
  Project,
  useGetProjectByIdQuery,
} from "graphql/_Types";
import {
  UserProjectAccessDjango,
  useProjectPermission,
} from "hooks/useProjectPermission";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useTenantContext } from "providers/TenantProvider/TenantProvider";
import { useState, useMemo, useEffect, Fragment, useCallback } from "react";
import { SetRequired } from "type-fest";
import {
  UserProjectAccess,
  USER_ACCESS_LEVELS_BY_ID,
  USER_ACCESS_LEVELS_BY_KEY,
  UserAccessLevelKey,
  UserAccessLevel,
} from "types/UserAccessLevels";
import { addUtilityToastFailure } from "utils/addUtilityToastFailure";
import { addUtilityToastSuccess } from "utils/addUtilityToastSuccess";
import { isNonNullish } from "utils/isNonNullish";
import {
  extractPermissionsList,
  getRoleFromPermissions,
} from "utils/permissions";
import { UserWithAccessExternal } from "./UserWithAccessExternal";
import { FieldTextPermissioned } from "components/FieldTextPermissioned/FieldTextPermissioned";
import useFindUser from "hooks/useFindUser";
import { addUtilityToastPointing } from "utils/addUtilityToastPointing";
import { ideasFeatures } from "ideas.features";
import { useIdeasSearchParams } from "hooks/useIdeasSearchParams";

export interface ModalProjectSharingProps {
  projectId: Project["id"];
  onClose: () => void;
}
type TSharing = "shared" | "restricted";

export type TUserRole = UserAccessLevelKey | "custom";
export type TUserWithAccess = {
  id: UserProjectAccess["id"];
  userId: string;
  role: TUserRole;
};

export type TUserWithAccessExternal = {
  id: UserProjectAccess["id"];
  email: string;
  invitee: ApplicationUser["id"] | null;
  status: number;
  project_access_level: UserAccessLevel["id"];
};

type TEuiComboBoxUserIdOption = SetRequired<
  EuiComboBoxOptionOption<string>,
  "value"
>;

type TProjectSharingTab = "organization" | "external";

export const ModalProjectSharing = ({
  projectId,
  onClose: onCloseFromProps,
}: ModalProjectSharingProps) => {
  const { getParam, deleteParam } = useIdeasSearchParams();
  const onClose = useCallback(() => {
    const isShareRouteParamSet = getParam("OPEN_MODAL") === "shareProject";
    if (isShareRouteParamSet) {
      deleteParam("OPEN_MODAL");
    }
    onCloseFromProps();
  }, [deleteParam, getParam, onCloseFromProps]);

  const { data: ProjectData, loading: loadingProjectData } =
    useGetProjectByIdQuery({
      variables: { id: projectId },
      fetchPolicy: "cache-and-network",
    });

  const [projectAccess, setProjectAccess] =
    useState<UserProjectAccessDjango[]>();

  const currentUser = useUserContext((s) => s.currentUser);
  const tenantUsers = useTenantContext((s) => s.tenantUsers);
  const {
    fetchCurrentProjectAccesses,
    fetchCurrentProjectAccessesExternal,
    createIndividualUserAccessLevels,
    updateOrganizationUserAccessLevel,
    inviteExternalUser,
  } = useProjectPermission();
  const [selectedTab, setSelectedTab] =
    useState<TProjectSharingTab>("organization");
  const [isWorking, setWorking] = useState(false);
  const [sharing, setSharing] = useState<TSharing>("restricted");
  const [orgRole, setOrgRole] = useState<UserAccessLevelKey>("copier");
  const [isLoadingOrgRole, setIsLoadingOrgRole] = useState<boolean>(false);
  const [userRole, setUserRole] =
    useState<Exclude<TUserRole, "custom">>("copier");
  const [usersWithAccess, setUsersWithAccess] = useState<TUserWithAccess[]>([]);
  const [usersWithAccessExternal, setUsersWithAccessExternal] = useState<
    TUserWithAccessExternal[]
  >([]);
  const [selectedUserIds, setSelectedUserIds] =
    useState<TEuiComboBoxUserIdOption[]>();

  const [emailToInvite, setEmailToInvite] = useState<string>("");
  const [sendingInvite, setSendingInvite] = useState<boolean>(false);

  const { findUser } = useFindUser();

  // Populating the initial data for the project
  useEffect(() => {
    if (ProjectData?.projectById && usersWithAccess.length === 0) {
      if (projectAccess === undefined) {
        void fetchCurrentProjectAccesses().then((data) => {
          setProjectAccess(data);
        });

        void fetchCurrentProjectAccessesExternal().then((data) => {
          setUsersWithAccessExternal(data);
        });

        return; // Wait for the project access to be populated
      }

      // Populating the organization access
      const initialOrgRole =
        USER_ACCESS_LEVELS_BY_ID[
          ProjectData.projectById.defaultUserAccessLevel
        ];
      setSharing(initialOrgRole?.id !== 1 ? "shared" : "restricted");
      setOrgRole(initialOrgRole?.key ?? "copier");

      // Populating the users with access list
      const initialUsersWithAccess: TUserWithAccess[] = projectAccess
        .map((node) => ({
          id: node.id,
          userId: node.user,
          role: getRoleFromPermissions(extractPermissionsList(node)),
        }))
        // Sort alphabetically by name
        .sort((a, b) => {
          const userA = tenantUsers.find((user) => user.id === a.userId);
          const userB = tenantUsers.find((user) => user.id === b.userId);
          if (!userA || !userB) return 0;
          return (userA.firstName + " " + userA.lastName).localeCompare(
            userB.firstName + " " + userB.lastName,
          );
        });

      // Add project owner to the list (As a managed user it will be removed in the previous step)
      // Placing the owner always at the top of the list
      initialUsersWithAccess.unshift({
        id: "owner", // We do not actually need this permission id because the owner is not a real permission (Inferred from that the project creator is the owner)
        userId: ProjectData.projectById.userId,
        role: "owner",
      });

      setUsersWithAccess(initialUsersWithAccess);
    }
  }, [
    ProjectData,
    fetchCurrentProjectAccesses,
    fetchCurrentProjectAccessesExternal,
    projectAccess,
    tenantUsers,
    usersWithAccess,
  ]);

  const optionsTenantUsers = useMemo(
    () =>
      tenantUsers
        .filter(({ internal }) => !internal)
        // Remove project owner from the list
        .filter(({ id }) => id !== ProjectData?.projectById?.userId)
        // remove existing users from the list
        .filter(
          ({ id }) =>
            !usersWithAccess.some(
              (userWithAccess) => userWithAccess.userId === id,
            ),
        )
        .map((user) => ({
          label: user.firstName + " " + user.lastName,
          value: user.id,
          prepend: <UserAvatar userId={user.id} size="s" />,
          append: (
            <EuiText size="xs" color="subdued">
              {user.email}
            </EuiText>
          ),
          css: {
            height: "31px !important",
          },
        }))
        .sort((a, b) => a.label.localeCompare(b.label)), // Sorting by name

    [ProjectData?.projectById?.userId, tenantUsers, usersWithAccess],
  );

  const optionsAccessLevels: EuiSuperSelectOption<UserAccessLevelKey>[] = [
    // All internal users with "Viewer" permission are invited as copiers.
    // Only external users with have the true "Viewer" role.
    {
      value: "copier",
      inputDisplay: <EuiText size="s">Viewer</EuiText>,
      dropdownDisplay: (
        <>
          <strong>Viewer</strong>
          <EuiText size="s" color="subdued">
            <p>View project and files only</p>
          </EuiText>
        </>
      ),
    },
    {
      value: "editor",
      inputDisplay: <EuiText size="s">Editor</EuiText>,
      dropdownDisplay: (
        <>
          <strong>Editor</strong>
          <EuiText size="s" color="subdued">
            <p>Create/edit tables, upload files, and run analyses</p>
          </EuiText>
        </>
      ),
    },
    {
      value: "admin",
      inputDisplay: <EuiText size="s">Admin</EuiText>,
      dropdownDisplay: (
        <>
          <strong>Admin</strong>
          <EuiText size="s" color="subdued">
            <p>Manage access, delete project</p>
          </EuiText>
        </>
      ),
    },
  ];

  const handleAddUsers = async () => {
    if (selectedUserIds && selectedUserIds.length > 0) {
      setWorking(true);

      //
      // Add new users to the bottom of the list
      //

      try {
        const response = await createIndividualUserAccessLevels(
          selectedUserIds.map((user) => ({
            userId: user.value,
            userAccessLevel: userRole,
          })),
        );

        const newUsers = response.map((item) => ({
          id: item.id,
          userId: item.userId,
          role: userRole,
        }));

        setUsersWithAccess((existingUsers) => [...existingUsers, ...newUsers]);
        addUtilityToastSuccess("Successfully added users");
        setSelectedUserIds([]);
      } catch (error) {
        addUtilityToastFailure("Failed to add users");
        captureException(error);
      } finally {
        setWorking(false);
      }
    }
  };

  const handleInviteUser = async () => {
    if (emailToInvite === currentUser.email) {
      ideasFeatures.devOnly
        ? addUtilityToastPointing("You cannot invite yourself")
        : addUtilityToastFailure("You cannot invite yourself");
      return;
    }
    if (tenantUsers.some((user) => user.email === emailToInvite)) {
      addUtilityToastFailure(
        "User already exists in the organization - use the organization access tab to share this project with them",
      );
      return;
    }
    setSendingInvite(true);

    try {
      // Check if the user exists
      await findUser(emailToInvite);
    } catch (error) {
      addUtilityToastFailure(
        `No current IDEAS user found with this email address: ${emailToInvite}. Inviting new users to IDEAS will be possible in the next release.`,
      );
      setSendingInvite(false);
      return;
    }

    try {
      const newExternalUser = await inviteExternalUser(emailToInvite);

      setUsersWithAccessExternal((prevFields) => [
        ...prevFields,
        {
          id: newExternalUser.id,
          email: newExternalUser.email,
          invitee: newExternalUser.invitee,
          status: newExternalUser.status,
          project_access_level: newExternalUser.project_access_level,
        },
      ]);

      setEmailToInvite("");

      addUtilityToastSuccess("User invited");
    } catch (error) {
      addUtilityToastFailure("Failed to invite user");
    }
    setSendingInvite(false);
  };

  const handleRoleChange = (userWithAccessUpdated: TUserWithAccess) => {
    setUsersWithAccess((prevFields) => [
      ...prevFields.map((user) =>
        user.userId === userWithAccessUpdated.userId
          ? userWithAccessUpdated
          : user,
      ),
    ]);
  };

  const handleRoleRemove = (userId: TUserWithAccess["userId"]) => {
    setUsersWithAccess((prevFields) => [
      ...prevFields.filter((user) => user.userId !== userId),
    ]);
  };

  const handleRoleChangeOrg = async (role: UserAccessLevelKey) => {
    if (role === orgRole) return;
    setIsLoadingOrgRole(true);
    const accessLevelId = USER_ACCESS_LEVELS_BY_KEY[role]?.id;
    assert(
      accessLevelId !== undefined,
      "Unexpected role value for USER_ACCESS_LEVELS_BY_KEY",
    );
    try {
      await updateOrganizationUserAccessLevel(projectId, accessLevelId);
      setOrgRole(role);
      addUtilityToastSuccess("Successfully updated organization access level");
    } catch (error) {
      addUtilityToastFailure("Failed to update organization access level");
      captureException(error);
    }
    setIsLoadingOrgRole(false);
  };

  const handleRoleRevoke = (id: TUserWithAccessExternal["id"]) => {
    setUsersWithAccessExternal((prevFields) => [
      ...prevFields.filter((user) => user.id !== id),
    ]);
  };

  if (loadingProjectData || projectAccess === undefined) {
    return <ModalLoading onClose={onClose} />;
  }

  const project = ProjectData?.projectById;
  if (!isNonNullish(project)) {
    return <ModalError onClose={onClose} />;
  }

  const organizationTab = (
    <Fragment>
      <EuiPanel hasShadow={false} hasBorder={true}>
        <EuiForm>
          <EuiFormRow
            fullWidth
            error={
              "You do not have sufficient permission to change this setting"
            }
            label="Access for all users in the organization"
          >
            <>
              <SuperSelectPermissioned
                requiredPermission="grantAccess"
                fullWidth
                compressed
                options={[
                  {
                    value: "restricted",
                    inputDisplay: (
                      <EuiFlexGroup alignItems="center" gutterSize="s">
                        <EuiFlexItem grow={false}>
                          <EuiIcon type="lock" size="m" />
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiText size="s">Restricted</EuiText>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    ),
                    dropdownDisplay: (
                      <>
                        <strong>Restricted</strong>
                        <EuiText size="s" color="subdued">
                          <p>
                            Only visible to you and users granted individual
                            access
                          </p>
                        </EuiText>
                      </>
                    ),
                  },
                  {
                    value: "shared",
                    inputDisplay: (
                      <EuiFlexGroup alignItems="center" gutterSize="s">
                        <EuiFlexItem grow={false}>
                          <EuiIcon type="users" size="m" />
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiText size="s">Shared</EuiText>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    ),
                    dropdownDisplay: (
                      <>
                        <strong>Shared</strong>
                        <EuiText size="s" color="subdued">
                          <p>Any user in the organization can access</p>
                        </EuiText>
                      </>
                    ),
                  },
                ]}
                valueOfSelected={sharing}
                placeholder="Select an option"
                onChange={(value) => {
                  void handleRoleChangeOrg(
                    value === "shared" ? "copier" : "restricted",
                  );
                  setSharing(value);
                }}
                itemLayoutAlign="top"
                hasDividers
                data-test-subj="org-user-access"
              />

              {sharing === "shared" && (
                <>
                  <EuiSpacer size="s" />
                  <EuiText size="xs">Access level</EuiText>
                  <EuiSpacer size="xs" />
                  <SuperSelectPermissioned
                    isLoading={isLoadingOrgRole}
                    fullWidth
                    compressed
                    options={optionsAccessLevels}
                    valueOfSelected={orgRole}
                    placeholder="Select an option"
                    onChange={(value) => {
                      void handleRoleChangeOrg(value);
                    }}
                    itemLayoutAlign="top"
                    hasDividers
                    requiredPermission="grantAccess"
                    data-test-subj="org-user-access-level"
                  />
                </>
              )}
            </>
          </EuiFormRow>

          <EuiSpacer size="l" />

          <EuiFormRow label="Organization users with access" fullWidth>
            <>
              <EuiSpacer size="xs" />
              <EuiPanel hasShadow={false} hasBorder={true}>
                {usersWithAccess?.map((usersWithAccess) => {
                  return (
                    <UserWithAccess
                      key={usersWithAccess.userId}
                      usersWithAccess={usersWithAccess}
                      onRoleChange={(role: TUserRole) => {
                        handleRoleChange({
                          id: usersWithAccess.id,
                          userId: usersWithAccess.userId,
                          role,
                        });
                      }}
                      onRemove={() => {
                        handleRoleRemove(usersWithAccess.userId);
                      }}
                    />
                  );
                })}

                {usersWithAccess?.length !== 0 && <EuiSpacer size="l" />}

                <EuiText size="xs">
                  <h4>Add users from organization</h4>
                </EuiText>
                <EuiSpacer size="s" />

                {/* Eui does not have a good style for appending avatar. A custom style is needed to format properly */}
                <Global
                  styles={css`
                    .euiComboBox.euiComboBox--withAvatar {
                      .euiComboBoxPill {
                        height: 30px !important;
                        line-height: 26px;
                      }
                      .euiComboBoxOption__prepend {
                        .euiAvatar {
                          height: 12px;
                          width: 12px;
                        }
                      }
                    }
                  `}
                />
                <EuiFlexGroup gutterSize="xs">
                  <EuiFlexItem grow={true}>
                    <EuiFormRow fullWidth>
                      <ComboBoxPermissioned
                        fullWidth
                        style={{ maxWidth: "418px" }}
                        className="euiComboBox--withAvatar"
                        data-test-subj="add-user-access-combobox"
                        placeholder="Select users"
                        options={optionsTenantUsers}
                        selectedOptions={selectedUserIds}
                        onChange={(selectedOptions) => {
                          if (
                            selectedOptions.every(
                              (option): option is TEuiComboBoxUserIdOption =>
                                option.value !== undefined,
                            )
                          ) {
                            setSelectedUserIds(selectedOptions);
                          }
                        }}
                        isClearable={true}
                        requiredPermission="grantAccess"
                      />
                    </EuiFormRow>
                  </EuiFlexItem>
                </EuiFlexGroup>

                <EuiSpacer size="s" />
                <SuperSelectPermissioned
                  fullWidth
                  compressed
                  options={optionsAccessLevels}
                  valueOfSelected={userRole}
                  placeholder="Select an option"
                  onChange={(value) => setUserRole(value)}
                  itemLayoutAlign="top"
                  hasDividers
                  requiredPermission="grantAccess"
                />
                <EuiSpacer size="s" />
                <ButtonPermissioned
                  isLoading={isWorking}
                  size="s"
                  fullWidth
                  onClick={() => void handleAddUsers()}
                  requiredPermission="grantAccess"
                >
                  Add users
                </ButtonPermissioned>
              </EuiPanel>
            </>
          </EuiFormRow>
        </EuiForm>
      </EuiPanel>
    </Fragment>
  );
  const externalTab = (
    <Fragment>
      <EuiPanel hasShadow={false} hasBorder={true}>
        <EuiText size="xs">
          <h4>External users with access</h4>
        </EuiText>
        <EuiSpacer size="s" />

        <EuiPanel hasShadow={false} hasBorder={true}>
          {usersWithAccessExternal?.length === 0 && (
            <EuiCallOut>
              No external users have been invited to access this project
            </EuiCallOut>
          )}
          {usersWithAccessExternal?.map((usersWithAccess, idx) => {
            return (
              <Fragment key={usersWithAccess.id}>
                <UserWithAccessExternal
                  key={usersWithAccess.id}
                  usersWithAccess={usersWithAccess}
                  onRevoke={() => handleRoleRevoke(usersWithAccess.id)}
                  setUsersWithAccessExternal={setUsersWithAccessExternal}
                />
                {/* Put space between items except for the last child */}
                {idx + 1 < usersWithAccessExternal.length && (
                  <EuiSpacer size="m" />
                )}
              </Fragment>
            );
          })}
        </EuiPanel>

        <EuiSpacer size="l" />
        <EuiFormRow label="Invite external users" fullWidth>
          <EuiFlexGroup gutterSize="s">
            <EuiFlexItem>
              <FieldTextPermissioned
                type="email"
                placeholder="Enter email address"
                value={emailToInvite}
                onChange={(e) => {
                  setEmailToInvite(e.target.value);
                }}
                requiredPermission="grantAccess"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <ButtonPermissioned
                isLoading={sendingInvite}
                requiredPermission="grantAccess"
                size="m"
                onClick={() => void handleInviteUser()}
              >
                Invite user
              </ButtonPermissioned>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>
        <EuiSpacer size="m" />
        <EuiCallOut
          size="s"
          color="warning"
          title="External users can only have 'View' access"
          iconType="iInCircle"
        />
      </EuiPanel>
    </Fragment>
  );

  return (
    <EuiModal
      onClose={onClose}
      css={css`
        min-width: 540px;
        // matches the height to the max-block-size of EuiModals so it stays constant
        height: 75vh;
      `}
    >
      <EuiModalHeader>
        <EuiModalHeaderTitle>Share Project</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiTabs>
          <EuiTab
            onClick={() => setSelectedTab("organization")}
            isSelected={selectedTab === "organization"}
          >
            Organization Access
          </EuiTab>
          <EuiTab
            onClick={() => setSelectedTab("external")}
            isSelected={selectedTab === "external"}
          >
            External access
          </EuiTab>
        </EuiTabs>
        {selectedTab === "organization" ? organizationTab : externalTab}
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>Close</EuiButtonEmpty>
      </EuiModalFooter>
    </EuiModal>
  );
};
